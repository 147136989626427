/* Google fonts */
/* @import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Karla:wght@400;800&family=Mulish:wght@200;300;400;500;600;700;900&display=swap'); */
@font-face {
	font-family: glacialIndifRegular;
	src: url(../fonts/GlacialIndifference-Regular.otf);
}

@font-face {
	font-family: glacialIndifBold;
	src: url(../fonts/GlacialIndifference-Bold.otf);
}

/* Basic Setup starts */
* {
	margin: 0;
	padding: 0;
	outline: 0;
}

:root {
	--glacialIndifReg: glacialIndifRegular;
	--glacialIndifBold: glacialIndifBold;
	--turquoise: #14f2f8;
	--yellow: #ebc11a;
	--blue2: #5c91b6;
	--blue3: #1b7895;
	--blue1: #37a1d2;
	--light: #ffffff;
	--dark: #000000;
}

html {
	scroll-behavior: smooth;
	font-size: 62.5%;
}

body {
	font-family: 'glacialIndifRegular', 'glacialIndifBold', sans-serif !important;
}

.font-chzday {
	font-size: 16px;
}

.banner-text-inner h1 {
	font-family: 'glacialIndifBold', sans-serif;
}

h2,
h3,
h4,
h5,
h6,
p,
a {
	font-family: 'glacialIndifRegular', sans-serif;
}

ul {
	list-style: none !important;
	padding-left: 0 !important;
}

a {
	text-decoration: none !important;
	font-size: 1.6rem;
}

.nav-link {
	cursor: pointer;
}

p {
	color: #707070;
	font-size: 1.6rem;
}

h4 {
	font-size: 2.5rem;
}

.tooltip {
	font-size: 16px !important;
}

span {
	font-size: 1.6rem;
}

.location-inner span {
	font-size: 14px;
}

.Toastify__toast-body {
	text-transform: capitalize;
}

.g-profile-container {
	min-height: 75vh;
}

.modal-header {
	padding: 1rem 2rem !important;
}

.modal-title {
	font-size: 2.5rem !important;
}

.modal-header .btn-close {
	font-size: 1.6rem !important;
}

.modal-body {
	padding: 2rem !important;
}

/* .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
	width: 100%;
    padding: 0 2% !important;
} */

/* react-totify custom css start */
.grow-font-size {
	font-size: 16px !important;
}

/* react-tostify custom css start end */
.chuez-modal .modal-in-field {
	height: 400px;
	overflow-y: scroll;
	scrollbar-width: thin;
	scrollbar-color: var(--blue1) wheat;
	margin-bottom: 10px;
	padding-right: 5px;
	box-shadow: 0 10px 6px -6px #777;
}

.status-changed-modal .modal-header {
	border-bottom: 0;
}

@media (min-width:1200px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1200px;
	}
}

#partnerLogin,
#ForgotPass,
#LoginMail,
#SignUp,
#businessSignUp,
#editprofile {
	background: var(--blue2);
}

.gray-bg {
	background: #E5E5E5;
	min-height: 100vh
}

#dashboard,
#profileFeatures {
	background: var(--blue1);
}

.carousel-indicators {
	display: none !important;
}

.carousel-control-next,
.carousel-control-prev {
	width: 6rem !important;
	opacity: 1 !important;
	transition: opacity .15s ease;
	height: 5rem;
	top: 45% !important;
}

.form-control {
	border: 2px solid transparent !important;
}

.form-control:focus {
	box-shadow: none !important;
	border: 2px solid transparent !important;
}

.form-check-input:focus {
	outline: 0;
	box-shadow: none !important;
}

/* Basic Setup ends */

/* Reusable component starts */
.pt-100 {
	padding-top: 10rem;
}

.pb-60 {
	padding-bottom: 6rem;
}

.card {
	padding: 2rem;
	border: none !important;
	box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
	border-radius: 8rem 8rem 8rem 0 !important;
}

.primaryBtn {
	background: var(--blue1) !important;
	color: #fff !important;
	/* padding: 1.2rem 2rem !important; */
	text-decoration: none !important;
	border-radius: .5rem !important;
	font-weight: 700 !important;
	text-align: center !important;
	font-size: 1.8rem !important;
	cursor: pointer !important;
	border: none !important;
	transition: .2s all ease-in-out !important;
}

.primaryBtn:hover {
	background: var(--yellow);
	color: #fff;
}

.sectionHeading,
.sectionHeading span {
	color: var(--yellow);
	font-size: 4rem;
	font-weight: 700;
	text-transform: capitalize;
}

button {
	font-size: 1.6rem;
}

.btn-primary {
	color: #fff;
	background-color: var(--blue1) !important;
	border-color: var(--blue1) !important;
}

/* Reusable component ends */

/* Header Starts */
.loader_wrap {
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	z-index: 999;
}

.loader_wrap img {
	z-index: 99999;
}

#header {
	position: relative;
}

.distance-list {
	position: absolute;
	font-size: 16px;
	cursor: pointer;
	top: 10px;
}

.navbarWrap {
	position: absolute;
	z-index: 9;
}

.heroSlider {
	/* border-bottom: 20px solid #F3F2F3; */
	padding-bottom: 25px;
}

.heroSlider .slick-slide img {
	display: block;
	width: 100%;
	/* height: 60vh; */
	border-radius: 0 0 5rem;
	object-fit: cover;
}

.heroSlider .slick-arrow.slick-next {
	top: 50% !important;
	display: none !important;
}

.slick-prev,
.slick-next {
	width: 6.8rem !important;
	height: 5.5rem !important;
	color: #fff !important;
	z-index: 8;
}

.slick-prev::before,
.slick-next::before {
	font-family: 'slick';
	font-size: 3.5rem !important;
	line-height: 5.9rem !important;
	opacity: 1 !important;
	color: #fff !important;
}

.slick-dots {
	bottom: 2.5rem !important;
	z-index: 8;
}

.slick-dots li button::before {
	opacity: 1 !important;
	color: #8B8686 !important;
	font-size: 1.5rem !important;
}

.slick-dots li.slick-active button::before {
	color: #ffffff !important;
}

.slick-dots li {
	margin: 0 !important;
}

.heroSlider .slick-prev {
	left: 0 !important;
	display: none !important;
}

.slick-next {
	right: 0 !important;
}

.logo img {
	width: 18rem;
	height: auto;
}

.container.navbarWrap {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	right: 0;
}

.navbar {
	margin-top: 1.5rem;
	position: sticky;
	/* top: 100px; */
	z-index: 999;
}

/* navbar sticky starts */
#sticky-search-collapse1 {
	width: 100%;
	height: 45rem;
}

.navbar.active .logo {
	margin-right: 1rem;
}

.s-title h5,
.location-inner .ireland h5 {
	font-size: 1.8rem;
}

.location-popup {
	width: 25rem;
	margin-left: .8rem;
}

.navbar.active {
	z-index: 10;
	position: fixed;
	width: 100%;
	left: 0;
	margin-top: 0;
	box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.3);
	background: var(--blue2);
	top: 0;
}

#header .navbar.active {
	padding-left: 5% !important;
	padding-right: 5% !important;
}

.navbar.active .logo {
	margin-right: 2.5rem;
}

.navbar.active .logo img {
	width: 15rem;
}

.modal-body .logo img {
	height: 80px !important;
	width: 150px !important;
	object-fit: cover;
}

.navbar.active .primaryMenu li .navItem {
	font-size: 1.7rem;
	margin: 0;
	padding-right: 2rem;
	padding-left: 2rem;
}

.navbar.active .primaryMenu li .navItem:hover {
	color: var(--dark) !important;
}

.navbar.active .primaryMenu li .navItem::before {
	display: none;
}

.navbar.active .navItemActive::before {
	display: none;
}

.sticky-search-wrap {
	display: none;
}

.navbar.active .sticky-search-wrap {
	display: flex;
	align-items: center;
}

.navbar.active .navItemActive {
	color: var(--dark) !important;
}

.sticky-search-inner {
	position: relative;
	margin-right: 1rem;
}

.sticky-search-inner .fa-search {
	position: absolute;
	top: 1rem;
	left: 1.5rem;
	cursor: pointer;
	font-size: 2.2rem;
	color: gray;
}

.location-popup input,
.sticky-search-inner input {
	width: 20rem;
	padding: 1rem;
	padding-left: 4.8rem;
	border: .1rem solid #80808042;
	font-size: 14px;
	box-shadow: 0 0 .4rem 0 rgba(0, 0, 0, 0.1);
}

#sticky-search-collapse1 {
	position: absolute;
	background: #fff;
	border: .1rem solid #80808042;
	border-top: 0;
	padding: 1.5rem;
}

#sticky-search-collapse1 .categories-wrapper {
	justify-content: space-around;
	flex-wrap: wrap;
}

#sticky-search-collapse1 .categories-wrapper span {
	display: inline-block;
	/* float: right; */
	font-size: 14px;
	font-weight: 500;
	color: white;
	text-decoration: none;
	border: none;
	padding: 2px 8px;
	border-radius: 4rem;
	margin: 5px 2px;
	text-transform: capitalize;
	cursor: pointer;
	opacity: 1;
	background-color: var(--blue1);
	transition: 0.5s ease;
}

#sticky-search-collapse1 .categories-wrapper span:hover {
	opacity: 0.5;
}

#sticky-search-collapse1 .categories-wrapper a .fa-star {
	margin-right: .5rem;
}

.recent-search-wrapper h5 {
	margin-top: 2.5rem;
	margin-bottom: 1.2rem;
	font-size: 2rem;
}

.recent-search-wrapper a {
	text-decoration: none;
	color: #000;
	padding: 1.2rem 0;
	font-size: 1.8rem;
}

.recent-search-wrapper a:hover {
	background: #F3F2F3;
}

.recent-search-wrapper a .fab {
	margin-right: .8rem;
	padding-left: 1rem;
}

.search-location {
	position: relative;
}

.location-popup ul {
	font-size: 16px;
	z-index: 10;
	position: fixed;
}

.location-popup ul li {
	padding: 10px;
	transition: 0.3s ease;
	margin: 0 !important;
}

.search-location .location-inner .ireland {
	display: flex;
	align-items: center;
}

.location-inner h3 {
	color: var(--yellow);
}

.location-inner h3 span {
	cursor: pointer;
	font-weight: bolder;
	margin-bottom: 0;
	color: var(--yellow);
	padding: 12px;
	box-shadow: 0 0 .4rem 0 rgba(0, 0, 0, 0.1);
	background-color: white;
	border: .1rem solid hsla(0, 0%, 50.2%, .25882352941176473);
	display: inline-block;
	margin-top: 5px;
}

.s-title h5 {
	cursor: pointer;
	font-weight: bolder;
	margin-bottom: 0;
	color: #000;
	font-size: 1.8rem;
}

.location-inner .ireland span {
	font-size: 2rem;
	margin-right: .5rem;
	margin-bottom: .3rem;
}

.location-popup input {
	padding: 1rem;
	border-left: none;
	box-shadow: none;
}

#sticky-search-collapse2 a {
	text-decoration: none;
	font-size: 1.6rem;
	color: #000;
	padding: 1rem;
}

#sticky-search-collapse2 {
	padding: 1rem;
}

#sticky-search-collapse2 a:hover {
	background: #F3F2F3;
	color: #0a58ca;
}

#sticky-search-collapse2 a.near-me-location {
	color: #0d6efd;
}

#sticky-search-collapse2 a.near-me-location .far {
	margin-right: .8rem;
}
.sticky_humburger{
	display: none;
}
.navbar.active .guestProfile {
	margin-right: 32px;
}
/* navbar sticky end */

#partnerHeader .navbar.active,
#header .navbar {
	padding: 1rem 2rem !important;
}

.primaryMenu {
	padding-left: 0;
	display: flex;
	align-items: center;
	margin-bottom: 0;
	/* padding-bottom: 50px; */
}

.primaryMenu li .navItem {
	/* margin-right: 1.2rem;
	margin-left: 1.2rem; */
	padding: .8rem;
	font-size: 1.7rem;
	padding-right: 2rem;
	padding-left: 2rem;
	color: var(--yellow);
	font-weight: 700;
	position: relative;
	text-decoration: none;
}

.navItemActive {
	color: #ffffff !important;
}

.navItemActive::before {
	border: .2rem solid #ffffff;
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.primaryMenu li .navItem:hover {
	color: #ffffff !important;
	cursor: pointer;
}

.primaryMenu li .navItem:hover::before {
	border: .2rem solid #ffffff;
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.primaryMenu .dropdown-menu {
	min-width: 20rem;
	padding: 0;
	border: none;
	left: 3.5rem !important;
	top: 5.5rem !important;
	text-align: center;
	border: none;
}

.primaryMenu li.dropdownWrap {
	position: relative;
}

.primaryMenu li.dropdownWrap .navItem:hover::before {
	display: none;
}

.primaryMenu ul.submenu {
	opacity: 0;
	visibility: hidden;
	width: 20rem;
	padding: 0;
	font-weight: 400;
	color: var(--dark);
	text-align: center;
	background-color: transparent;
	font-size: 2rem;
	font-weight: 600;
	position: absolute;
	background: #ffffff;
	top: 8.5rem;
	box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
	transition: .3s all ease-in-out;
	right: 3rem;
}

ul.primaryMenu li:hover ul.submenu {
	opacity: 1;
	visibility: visible;
	top: 5rem;
}

.submenu li a {
	color: var(--yellow) !important;
	width: 100%;
	display: block;
	margin: 0;
	padding: 1.5rem;
	text-align: center;
	text-decoration: none;
	border-left: .4rem solid #fff;
}

.submenu li a:hover::before {
	display: none;
}

.submenu li a:hover {
	border-color: var(--yellow);
	background-color: #F1EFEF !important;
	color: var(--dark) !important;
}

.scroll {
	padding-top: 28rem;
	padding-bottom: 0;
	padding-left: 1.7rem;
}

.banner-text-inner {
	padding-top: 18rem;
	padding-bottom: 0;
	padding-left: 1.7rem;
}

.slider-text-inner {
	top: 15rem;
	padding-left: 3rem;
}

.slider-text-inner h1 {
	font-size: 3.5rem;
	color: var(--yellow);
	font-weight: bold;
	margin-bottom: 0;
	text-transform: capitalize;
	width: 60%;
	font-family: 'glacialIndifBold', sans-serif;
}

/* #headerSearch {
	width: 55rem;
	height: 5rem;
	border-radius: 3.5rem;
	border: none;
	padding: 3rem;
	font-size: 2.5rem;
	position: relative;
	color: #707070;
} */
#headerSearch {
	width: 40rem;
	/* height: 5rem; */
	border-radius: 3.5rem;
	border: none;
	padding: 1rem;
	font-size: 1.5rem;
	position: relative;
	color: #707070;
}

.single-distance {
	background-color: transparent;
	text-align: center;
	padding: 5px;
	transition: 0.4s ease;
}

.single-distance:hover,
.selected-distance {
	background-color: #37a1d2;
	color: white;
}

.banerTextBottom {
	width: 30rem;
}

.banner-search-wrap {
	position: relative;
	width: 40rem;
}

.banner-search-icon .fas.fa-search {
	position: absolute;
	display: block;
	top: 1.2rem;
	right: 2rem;
	font-size: 2rem;
	color: #707070;
	font-weight: 500;
}

.download-app {
	display: flex;
	align-items: center;
	/* width: 90%; */
	justify-content: center;
	/* padding-right: 5px; */
}

.download-app img {
	width: 15rem;
}

.gplay img {
	display: block;
}

/* Header ends */
.app_section {
	background-color: var(--blue1);
	padding-top: 40px;
	padding-bottom: 40px;
}

.app_download_img img {
	transform: rotate(-10deg);
}

/* Services starts */
#service {
	margin-top: 7.5rem;
	padding-bottom: 3rem;
	position: relative;
}

.service-info h4 {
	color: var(--dark);
	font-size: 1.9rem;
	font-weight: 700;
	text-transform: uppercase;
}

.service-info h2 {
	color: var(--blue2);
	font-size: 2.5rem;
	font-weight: 700;
	text-transform: uppercase;
	width: 90%;
}

.service-info p {
	margin-top: 2rem;
	margin-bottom: 2.5rem;
	width: 85%;
}

.head-distance {
	text-decoration: none;
	background: var(--yellow);
	color: #ffffff;
	font-size: 14px;
	border-radius: 3rem;
	display: inline-block;
	cursor: pointer;
}

.service-info button {
	font-size: 16px;
}

select.head-distance {
	font-size: 16px;
}

.row.serviceItem .card {
	width: 93% !important;
	height: 150px;
	margin-bottom: 10px;
	text-align: center;
	background-color: var(--blue1);
	cursor: pointer;
	transition: 0.5s ease;
}

.row.serviceItem .card:hover {
	background-color: var(--blue3);
}

.serviceItem .slick-prev {
	left: -14px;
}

.serviceItem .slick-prev::before,
.serviceItem .slick-next::before {
	font-size: 2.5rem !important;
}

.serviceItem .card img {
	width: 50px !important;
	text-align: center;
	border-radius: 50%;
	height: 50px !important;
	object-fit: cover;
	margin-bottom: 1rem;
	background-color: white;
	padding: 5px;
}

.serviceItem .card-title.h5 {
	font-size: 2rem;
	text-transform: capitalize;
	margin-bottom: 2.5rem;
	color: var(--dark);
	font-weight: 700;
	cursor: pointer;
	text-transform: capitalize;
}

#service {
	margin-top: 5.5rem;
	padding: 2rem;
	background-color: white;
}

/* Services ends */

/* Venues starts */
.container-custom {
	width: 100% !important;
}

#Venu {
	background: #F3F2F3;
	padding-top: 5rem;
	padding-bottom: 10rem;
}

.venuItemWrap {
	width: 90%;
	margin: 0 auto;
}

.venu-heading h2 {
	color: var(--yellow);
	font-size: 4rem;
	margin-bottom: 4rem;
	font-weight: 700;
	padding-left: 3rem;
}

.venuItemInfo .venuTtemName {
	display: block;
	color: #707070;
	font-size: 2.3rem;
	font-weight: 700;
	text-decoration: none;
}

.row.venuItem.nearVenuItem .venuTtemName span {
	font-size: 2.2rem;
}

.venuItem .slick-prev {
	left: 0;
	/* display: none !important; */
}

.venuItemInfo .venuTtemLocation {
	color: #707070;
	text-decoration: none;
	margin-top: .4rem;
	display: block;
	margin-bottom: 1rem;
	height: 40px;
}

.venuItemImg {
	height: 28rem;
	width: 100%;
}

.venuItem .venuItemImg img {
	border-radius: 2rem;
	width: 100%;
	height: 26.5rem;
	margin-bottom: .8rem;
	object-fit: cover;
}

.ratingWrap {
	display: flex;
	align-items: center;
	color: #FF9900;
}

.venuItem .venuBtn {
	color: #fff !important;
	text-align: center;
	font-size: 1.8rem;
	font-weight: 500;
	background: var(--yellow);
	opacity: 1;
	transition: 0.5s ease;
}

.venuItem .venuBtn:hover,
.venuItem .venuBtn:disabled {
	opacity: 0.7;
}

.inactive-venu-btn {
	font-size: 1.8rem !important;
	font-weight: 500 !important;
	color: #504F4F !important;
	background-color: #fff !important;
}

.ratingWrap .icon .fa-star {
	color: #FF9900;
	font-size: 2rem;
	margin-right: .8rem;
}

.ratingWrap .count {
	font-size: 2rem;
	font-weight: 600;
}

/* Venues ends */

/* Feature starts */
#Feature {
	position: relative;
	background: #E6E6E6;
}

.FeatureSlider img {
	width: 100% !important;
	height: 60vh;
	object-fit: cover;
}
.FeatureSlider iframe{
   width: 100% !important;
	height: 60vh !important;
}

.FeatureSlider .slick-arrow.slick-next {
	top: 50% !important;
}

.FeatureSlider .slick-arrow.slick-prev {
	top: 50% !important;
	left: 0;
}

.banerimgWrap {
	position: relative;
}

.banerimgWrap::after {
	background: rgba(0, 0, 0, 0.3);
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}

.FeatureSlider .caption {
	position: absolute;
	right: 0;
	bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
	color: #fff;
	text-align: left;
	z-index: 99;
	width: 52%;
}

.FeatureSlider .caption h1 {
	font-size: 6.2rem;
	border-left: .3rem solid #fff;
	margin-bottom: 1.5rem;
	font-family: "glacialIndifBold", sans-serif !important;
	font-weight: 800;
}

.FeatureSlider .caption h1 a {
	/* display: block; */
	color: #ffffff;
	text-decoration: none;
	font-size: 6.2rem;
	position: relative;
	padding-bottom: 1rem;
}

.FeatureSlider .caption h1 a::after {
	content: "";
	width: 100%;
	background: var(--blue1);
	left: 0;
	top: 0;
	position: absolute;
	z-index: -1;
	bottom: 0;
}

/* Feature ends */

/* Testimonial starts */
#Testimonial {
	padding: 4rem 0;
	position: relative;
}

.container-fluid.testimonialContainer {
	padding: 0 10% !important;
}

.container.testimonialItem {
	position: relative;
}

#Testimonial::before {
	position: absolute;
	content: "";
	background: #fff;
	width: 100%;
	height: 24.5rem;
	bottom: 2rem;
}

#Testimonial .venu-heading h2 {
	padding-left: 6rem;
}

.testimonialItem .slick-arrow.slick-prev {
	left: 0;
	/* display: none !important; */
}

.testimonialItem .slick-prev {
	background: #403E3E !important;
	top: 50% !important;
	width: 3rem !important;
	height: 3rem !important;
	padding: 0 !important;
	border-radius: 50%;
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.testimonialItem .slick-next {
	background: #403E3E !important;
	top: 50% !important;
	width: 3rem !important;
	height: 3rem !important;
	padding: 0 !important;
	border-radius: 50%;
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.testimonialItem .slick-arrow.slick-next {
	right: 0;
	/* display: none !important; */
}

.testimonialCard {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.testimonialCard .card-text {
	height: 160px;
}

.testimonialCard::after {
	position: absolute;
	bottom: -3.7rem;
	content: "''";
	display: block;
	right: 4rem;
	font-size: 8.2rem;
	font-family: 'Cinzel', serif !important;
	color: var(--blue1);
	width: 5rem;
	font-weight: 700;
}

.testimonialItem .card img {
	width: 13rem !important;
	text-align: center;
	border-radius: 50%;
	height: 13rem !important;
	object-fit: cover;
	/* text-align: center; */
	margin-right: auto;
	margin-bottom: 4rem;
}

.testimonialItem .card-title.h5 {
	color: var(--blue1);
	font-size: 2.5rem;
	font-weight: 600;
	margin-bottom: 0;
}

.testimonialCard span {
	font-size: 1.8rem;
	display: block;
	margin-bottom: 1.5rem;
}

.testimonialCard {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	margin-bottom: 2rem;
	padding: .6rem;
	margin: 0 auto;
}

.testimonialItem .card .card-body {
	flex: 1 1 auto;
	padding: 0;
}

/* Testimonial ends */

/* Footer section starts */

#footer {
	background: var(--blue2);
}

.footer-container {
	max-width: 126rem;
}

#footer a {
	text-decoration: none;
}

.social-icon a,
.footer-social ul li a {
	width: 4rem;
	height: 4rem;
	display: inline-block;
	line-height: 4rem;
	font-size: 2.2rem;
	border-radius: 50%;
	text-align: center;
	padding: 0 !important;
	color: #fff;
	margin: 0 .5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer-social ul li a.fb {
	background: #1977F3 !important;
}

.footer-social ul li a.insta {
	background-image: linear-gradient(to right, #740FF3, #F502C8, #F72A25, #F79003, #F70F58);
}
.business-social-fb {
	background: #1977F3 !important;
}
.business-social-insta {
	background-image: linear-gradient(to right, #740FF3, #F502C8, #F72A25, #F79003, #F70F58);
}
.business-social-linkedin {
	background: #006192 !important;
}

/* .footer-widget.logo-widget {
	text-align: center;
} */
.social-list {
	align-items: center;
	/* justify-content: center; */
}

.footer-widget img {
	width: 15rem;
	height: auto;
	margin-bottom: 15px;
}

.footer-widget .footer-logo {
	margin-bottom: 2.5rem;
}

.footer-widget p {
	line-height: 1.8;
	font-size: 1.7rem;
	font-weight: 400;
}

#footer ul {
	margin: 0px !important;
}

.footer-link li {
	padding-top: 10px;
}

.footer-social ul.social-list li a {
	padding: 0 .8rem;
	transition: .4s ease-in-out;
}

.footer-social ul.social-list li a:hover {
	background-color: #440F26;
	color: #ffffff;
}

.footer-widget h3 {
	font-size: 18px;
	color: var(--dark);
	font-weight: 700;
}

.footer-widget ul.footer-link li a {
	color: var(--light);
	font-size: 16px;
	font-weight: 500;
	text-transform: capitalize;
}

.footer-widget ul.footer-link li:hover a {
	color: var(--yellow) !important;
	letter-spacing: .1rem;
}

.footer-widget ul.footer-link li a {
	transition: .5s ease-in-out;
}

/* Footer section ends */

/* Partner Login page starts */
#partnerLogin .LoginTop .title {
	font-size: 2.4rem;
	margin-bottom: 2.4rem;
	font-weight: 700;
}

#partnerLogin .LoginTop .info {
	color: #707070;
	font-size: 1.8rem;
	margin-bottom: 2.4rem;
}

/* Partner Login page ends */

/* Business signup starts */
#businessSignUp .LoginInner {
	max-width: 65rem;
}

#businessSignUp .LogoWrap .Logo img {
	width: 13rem;
}

#businessSignUp .PhoneInputInput {
	color: #212529 !important;
	border: .1rem solid #000000 !important;
	padding: 1rem !important;
	font-size: 1.8rem;
}

#businessSignUp .PhoneInputCountry {
	color: #212529 !important;
	border: .1rem solid #000000 !important;
	margin: 0 !important;
	border-right: 0 !important;
	font-size: 1.8rem;
	padding: 1rem !important;
}

.PhoneInputCountryIconUnicode {
	font-size: 18px;
}

.PhoneInputCountrySelect {
	font-size: 16px;
}

.PhoneInputCountryIcon {
	height: 20px !important;
	width: 30px !important;
}

#businessSignUp .signUpForm .form-control {
	padding: 1rem !important;
}

#businessSignUp .form-label {
	margin-bottom: 1rem;
	color: var(--dark);
	font-size: 2rem;
	font-weight: 600;
}

#businessSignUp .formInput .require {
	color: red;
	margin-left: 1rem;
	font-weight: 700;
}

#businessSignUp .formInput .inputtitle {
	color: var(--blue1);
	font-size: 2rem;
	font-weight: 600;
}

.map {
	margin-top: 1.5rem !important;
	margin-bottom: 1rem;
}

.mapWrapper:first-child {
	position: relative;
}

.mapWrapper {
	width: 100%;
	height: 37rem;
}

.mapWrapper a {
	font-size: inherit !important;
	color: inherit !important;
}

.passWrap1,
.passWrap2 {
	position: relative;
}

.passwordIcon1,
.passwordIcon2 {
	position: absolute;
	right: 1rem;
	bottom: 1.5rem;
	font-size: 1.8rem;
	color: var(--dark);
}

/* Business signup ends */

/* Guest Login page starts */
#Login {
	/* background: #E6E6E6; */
}

#Login .LogoWrap .Logo img {
	width: 13rem;
}

.LoginWrap {
	background: url(../images/banner-slider/login_bg.png);
	background-repeat: no-repeat;
	background-size: contain;
	width: 100%;
	height: 140vh;
	border-radius: 0 0 6.5rem 0;
	background-position: center;
	background-position-y: bottom;
	background-attachment: fixed;
}

.LogoWrap {
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#Login .LogoWrap .BackHome img {
	width: 3.5rem;
	cursor: pointer;
}

.LogoWrap .Logo img {
	width: 18rem;
}

.LoginInner {
	width: 100%;
	height: 100%;
	padding: 5rem 0;
	max-width: 45.6rem;
}

.LoginWrap .Overlay {
	/* background: rgba(0, 0, 0, 0.6); */
	width: 100%;
	height: 122vh;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0 0 6.5rem 0;
}

.LoginTop {
	background: #fff;
	padding: 2.4rem 4rem;
	border-radius: 2rem 2rem 0 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
}

.LoginTop .title {
	color: var(--yellow);
	font-size: 2.4rem;
	margin-bottom: 2.4rem;
	font-weight: 700;
}

.LoginTop .socio-btn {
	border: .1rem solid #000000;
	text-decoration: none;
	border-radius: .8rem;
	margin-bottom: 2rem;
	padding: 1rem;
	width: 100%;
	color: #1C1B1B;
	transition: .2s all ease-in-out;
	cursor: pointer;
	font-size: 1.8rem;
}

.LoginTop .socio-btn:hover {
	background: var(--blue1);
	color: #ffffff;
	border-color: var(--blue1);
}

.LoginTop .socio-btn img {
	width: 3rem;
	margin-right: 1.5rem;
}

.SignUp {
	text-align: center;
	margin-top: 0;
}

.SignUp span {
	display: block;
	font-size: 2rem;
	color: #707070;
}

.SignUp .SignUpBtn {
	color: var(--blue1);
	border: none;
	padding: 0;
	margin-bottom: 0;
	font-size: 1.8rem;
}

.SignUp .SignUpBtn:hover {
	background: transparent;
	color: var(--blue1);
}

.LoginBottom {
	background: #fff;
	margin-top: 2rem;
	padding: 2.4rem 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 2rem 0rem 2rem 2rem;
	box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
}

.LoginBottom .Text1 {
	color: var(--yellow);
	font-size: 2.4rem;
	font-weight: 700;
}

.LoginBottom .Text2 {
	display: block;
	font-size: 2rem;
	color: #707070;
	margin-bottom: 0;
	text-align: center;
}

#Login .LoginBottom .Text2 {
	margin-bottom: 0;
}

.LoginBottom .BloginBtn {
	color: var(--blue1);
	font-size: 1.8rem;
	text-decoration: none;
}

/* Guest Login page ends */

/* SignUp starts */
#SignUp .LoginTop {
	background: #fff;
	padding: 2.4rem 4rem;
	border-radius: 2.5rem 2.5rem 0 2.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0 .5rem .6rem 0 rgba(0, 0, 0, 0.2);
}

#SignUp .LoginInner {
	width: 100%;
	height: 100%;
	padding-top: 2.4rem;
	max-width: 65rem;
	padding-bottom: 6rem;
}

#SignUp .signUpForm {
	width: 100%;
}

#SignUp .formInput {
	padding: 0;
}

#inlineFormInputGroup {
	border-left: none !important;
}

.chuez-modal .form-control,
#SignUp .signUpForm .form-control {
	padding: 1rem !important;
}

.mailLoginWrap .form-label {
	margin-bottom: 1rem;
	color: var(--dark);
	font-size: 2rem;
	font-weight: 600;
}

.sticky-search-wrap .input-group-text {
	background-color: white !important;
	color: var(--yellow);
	cursor: pointer;
	border: none;
	margin-top: 2px;
	border-radius: 0;
	transition: 0.5s ease;
}

.sticky-search-wrap .input-group-text:hover {
	background-color: var(--yellow) !important;
	color: white;
}

.sticky-search-wrap .input-group-text i {
	font-size: 17px;
	padding: 8px 2px;
}

.checkCondition .form-check-input {
	border-radius: 0 !important;
}

.checkCondition #formGridCheckbox {
	display: inline-block;
	margin-bottom: 0 !important;
	font-size: 2.5rem;
	margin-right: 1.2rem;
}

.checkCondition {
	display: flex;
	align-items: center;
}

.checkCondition span {
	font-size: 1.8rem;
	font-weight: 500;
}

#SignUp .signUpBtn {
	margin: 1.5rem 0;
}

/* SignUp ends */

/* Login With mail starts */
.mailLoginWrap {
	display: flex;
	align-items: center;
	justify-content: center;
}

.BackHome img {
	width: 3.5rem;
	cursor: pointer;
}

.mailLoginWrap .LogoWrap .Logo img {
	width: 13rem;
}

.mailLoginWrap .LoginInner {
	width: 100%;
	height: 100%;
	padding-top: 2.4rem;
	max-width: 45.6rem;
	padding-bottom: 6rem;
}

.mailLoginWrap .LoginTop {
	background: #fff;
	padding: 2.4rem 4rem;
	border-radius: 2.5rem 2.5rem 0 2.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0 0 12px 0 rgb(0 0 0 / 8%);
}

.mailLoginWrap .LoginTop .title {
	font-size: 2.4rem;
	margin-bottom: 2.4rem;
	font-weight: 700;
}

form.loginMailForm {
	width: 100%;
}

/* .mailLoginWrap .loginMailForm .formInput {
	padding: 0 28px;
} */
.chuez-modal .form-control,
.PhoneInputInput,
.mailLoginWrap .form-control {
	color: #212529 !important;
	border: 2px solid transparent !important;
	padding: 1rem !important;
	font-size: 1.8rem;
	background-color: #e0f0ff;
	transition: 0.5s ease;
}

.booking-modal .modal-header {
	border-bottom: none;
}

.booking-modal .Logo img {
	width: 80px;
	margin: 0;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
}

.chuez-modal .form-control:focus,
.PhoneInputInput:focus,
.mailLoginWrap .form-control:focus {
	border: 2px solid var(--yellow) !important;
}

.mailLoginWrap .form-control::placeholder {
	color: var(--dark);
	opacity: 0.8;
	font-size: 1.8rem;
}

.mailLoginWrap .loginMailForm .form-label {
	margin-bottom: 1rem;
	color: var(--dark);
	font-size: 2rem;
	font-weight: 600;
}

.RegisterBtn,
.mailLoginWrap .LoginTop a {
	border: none !important;
	border-radius: 0;
	margin-bottom: 0;
	padding: 0;
	color: var(--blue1) !important;
	font-size: 1.8rem;
}

.RegisterBtn {
	font-size: 14px;
}

.mailLoginWrap .LoginTop a:hover {
	background: transparent;
}

.mailLoginWrap .signUpBtn {
	color: #FFFFFF;
	display: block;
	font-size: 1.8rem !important;
	font-weight: 600 !important;
	background: var(--blue1) !important;
	border: none !important;
	width: 100%;
	padding: 1rem 0 !important;
	border-radius: .25rem;
	margin: 1.5rem 0;
}

.register {
	text-align: center;
}

.register span {
	display: block;
	color: #707070;
	font-size: 2rem;
}

.mailLoginWrap .LoginBottom {
	background: #ffffff;
	margin-top: 2rem;
	border-radius: 2.5rem 0rem 2.5rem 2.5rem;
	box-shadow: 0 .5rem .6rem 0 rgba(0, 0, 0, 0.2);
	padding: 2.4rem 4.0rem;
}

.mailLoginWrap .LoginBottom .BloginBtn {
	margin-right: 1rem;
}

.passWrap,
.passWrap4 {
	position: relative;
}

.passwordIcon,
.passwordIcon4 {
	position: absolute;
	right: 2rem;
	bottom: 1.5rem;
	font-size: 2rem;
	color: var(--dark);
}

/* Login With mail ends */

/* ForgotPassword starts */
#ForgotPass .formInput span {
	color: #707070;
	font-size: 1.8rem;
	margin-bottom: 2.4rem;
	display: block;
}

#ForgotPass .LoginTop a img {
	width: 2.5rem;
	margin-right: 0.1rem;
}

#ForgotPass .LoginInner {
	padding-bottom: 7.5rem;
}

/* ForgotPassword ends */

/* Partner Profile Page starts */

/* Header starts */
#partnerHeader {
	background: #F2F2F2;
}

#partnerHeader .logo img {
	width: 18rem;
	height: auto;
}

#partnerHeader .active .logo img {
	width: 16rem;
}

#partnerHeader .container-fluid {
	width: 100%;
	padding: 0 5% !important;
}

#partnerHeader .headerWrap {
	background: url(../images/partnerProfile/Header/headerbg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 35vh;
}

#partnerHeader .navbar {
	margin-top: 0;
}

#partnerHeader .profileNav {
	display: flex;
	align-items: center;
	padding-left: 0;
	margin-bottom: 0;
}

.profileNav .navItem.first {
	color: #ffffff;
	text-decoration: none;
	font-size: 2rem;
	font-weight: 700;
}

.profileNav .navbar-list {
	width: 5.5rem;
	margin-left: 5rem;
}

/* Header ends */

/* Dashboard starts */
#dashboard .slick-arrow {
	display: none !important;
}

#dashboard .container-fluid {
	width: 100%;
	padding: 0 5% !important;
}

.mobile-app .heading,
#dashboard .heading {
	color: var(--blue2);
	font-size: 4rem;
	padding: 3rem 0;
	margin-bottom: 0;
	font-weight: 700;
}

.guest_app .heading {
	color: var(--yellow) !important;
}

.dashboardImg img {
	width: 100%;
	height: auto;
}

#dashboard .tabItemWrap {
	background: var(--blue1);
	padding-top: 3rem !important;
	border-radius: 3rem 0rem 0 3rem;
	width: 23% !important;
	padding-bottom: 3rem !important;
	padding-right: 0 !important;
}

#dashboard .tabItemWrap a i {
	color: #ffffff;
	font-size: 3.5rem;
	margin-right: 1rem;
}

#dashboard .tabItemWrap .nav-item a {
	font-size: 3rem;
	color: #ffffff;
	font-weight: 700;
	margin: 1rem 0;
	padding: 1.4rem 2.8rem;
}

/* #dashboard .tabItemWrap .nav-item a:hover {
	cursor: pointer;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
	background-color: #CCCCCC !important;
	color: var(--yellow) !important;
	border-radius: 40px 0 0 40px;
}
.nav-pills .nav-link.active i, .nav-pills .show > .nav-link {
	color: var(--yellow) !important;
} */
.tabContentWrap {
	padding-left: 0 !important;
	background: #CCCCCC;
	border-radius: 0 3rem 3rem 0rem;
	padding-right: 0 !important;
}

#dashboard .tab-content {
	padding: 0 2rem;
}

.dashboardTop {
	height: 10rem;
	background: #ffffff;
	margin-bottom: 3.5rem;
	border-radius: 0 3rem 0 0;
	display: flex;
	align-items: center;
	justify-content: end;
}

.dashboardNav {
	padding-left: 0;
	display: flex;
	padding-right: 2rem;
	margin-bottom: 0;
}

.dashboardNav li a {
	color: #757373;
	font-size: 3.8rem;
}

.dashboardNav li {
	margin: 0 1.8rem;
}

.calendarNav {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.calendarNav .form-select {
	border: none;
	border-radius: 0;
	box-shadow: 0 .5rem .5rem 0 rgba(0, 0, 0, 0.1);
	font-size: 2.9rem;
	color: var(--yellow);
	font-weight: 700;
	width: 27.5rem;
}

.settingBtn img {
	width: 4rem;
}

.navItem.settingBtn {
	background: #fff;
	padding: .7rem 3rem;
	box-shadow: 0 .5rem .5rem 0 rgba(0, 0, 0, 0.1);
}

.addBtn {
	background: #292929;
	color: #ffffff;
	font-size: 2rem;
	text-decoration: none;
	font-weight: 700;
	padding: 1.5rem 1.8rem;
}

.calendarChart img {
	width: 100%;
	height: auto;
	margin-top: 5rem;
}

/* Dashboard ends */

/* Features starts */
.featuresInner {
	text-align: center;
}

.featuresInner .heading h2 {
	color: var(--yellow);
	font-size: 4.8rem;
	font-weight: 750;
}

.featuresInner .heading h2 span {
	color: var(--yellow);
	font-size: 4.8rem;
}

.featuresInner .heading p {
	color: #404040;
	font-size: 3.2rem;
	font-weight: 300;
}

.featuresInner .heading {
	padding-top: 2rem;
	padding-bottom: 1rem;
}

.featureCard {
	background: #fff;
	padding: 2rem 3.5rem;
	border-radius: 1.5rem;
	box-shadow: .1rem .1rem .1rem .1rem rgba(0, 0, 0, 0.1);
	transition: 0.5s ease;
}

.featureCard:hover {
	background-color: var(--blue3);
	color: var(--light) !important;
}

.featureCard img {
	width: 15%;
}

.featureCard h5 {
	color: var(--yellow);
	font-size: 2.4rem;
	font-weight: 400;
	margin: .8rem 0;
}

.featureCard p {
	color: #707070;
	font-size: 1.3rem;
	font-weight: 400;
	text-align: left;
	margin-bottom: 0;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	overflow: hidden;
	-webkit-box-orient: vertical;
}

.featuresInner {
	text-align: center;
}

.featuresWrap {
	position: relative;
	background: var(--blue1);
	width: 100%;
	height: auto;
	border-radius: 40rem 0 40rem 40rem;
}

.bg-man img {
	width: 100%;
	height: auto;
	/* position: absolute; */
	/* bottom: 0; */
	/* left: 5rem; */
}

.bg-text {
	/* position: absolute; */
	right: 0;
	top: 34%;
	width: 100%;
}

.feature-bottom {
	padding-top: 150px;
}

.bg-text h5 {
	font-size: 4.8rem;
	font-weight: 400;
	color: var(--yellow);
}

.bg-text h2 {
	margin-left: 6.4rem;
	font-size: 3.8rem;
	font-weight: 800;
	margin-bottom: 0;
	margin-top: 2.5rem;
	color: var(--yellow);
}

#partnerFooter #footer {
	background: var(--blue2);
}

/* Features ends */

/* Partner Profile ends */

/* Services starts */
#systemHeader,
#filterHeader {
	background: var(--blue1);
	height: 57px;
	border-radius: 0 0 5.5rem 0;
	box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.2);
}

#systemHeader {
	background-color: #f3f2f3;
}

#filterHeader .logo img {
	width: 12rem;
	margin-bottom: 7px;
}

#filterHeader .container-fluid {
	padding: 0 4% !important;
}

#filterHeader .navbar {
	margin-top: 0;
	padding-top: 2rem;
}

.filterNav {
	padding-left: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
}

.filterNav li a {
	text-decoration: none;
	color: var(--yellow);
	font-size: 2rem;
	font-weight: 700;
}

.filterNav li {
	padding-left: 3rem;
}

.filterNav li .navbar-list {
	width: 70%;
}

#filterHeader .banner-search-wrap {
	position: relative;
	width: 52rem;
	margin: 0 auto;
}

#filterHeader #headerSearch {
	width: 100%;
	height: 6rem !important;
	border-radius: 3rem;
	font-size: 2.2rem;
	position: relative;
	box-shadow: .0rem .2rem .2rem 0 rgba(0, 0, 0, 0.1);
	font-weight: 500;
}

#filterHeader .search-box {
	padding-top: 6rem;
}

#filterHeader .banner-search-icon .fas.fa-search {
	position: absolute;
	display: block;
	top: 1.7rem;
	right: 2.3rem;
	font-size: 3rem;
	color: #707070c9;
	font-weight: 600;
}

/* Search result starts */
#searchResult {
	margin: 3.5rem 0;
}

.topheading {
	margin-bottom: 3.5rem;
}

#searchResult .selectBox {
	display: flex;
	margin-bottom: 2rem;
	justify-content: start;
}

#searchResult .form-select {
	border: none;
	background: #F3F2F3;
	border-radius: 3.5rem;
	color: var(--blue1);
	outline: 0;
	padding: .5rem 1.5rem;
	font-size: 18px;
	font-weight: 400;
	box-shadow: 0 .2rem .2rem 0 rgba(0, 0, 0, 0.1);
	-webkit-appearance: none;
	appearance: none;
	padding-right: 4.5rem;
	cursor: pointer;
}

#searchResult .selectItem {
	position: relative;
}

.PhoneInputCountry {
	border: 1px solid var(--blue1);
	padding: 0px 10px 0px 15px;
	border-radius: 2px;
}

#searchResult .selectItem .arrow-down {
	position: absolute;
	top: 10px;
	right: 10px;
	color: var(--blue1);
	font-size: 2.5 rem;
	pointer-events: none;
	font-size: 1.8rem;
}

#searchResult .resultItem {
	background: #F3F2F3;
	padding-top: 5.5rem;
	padding-bottom: 7rem;
	border-radius: 3.5rem;
}

.resultItem h2 {
	color: var(--yellow);
	font-size: 3.5rem;
	font-weight: 700;
	padding-left: 3.5rem;
	margin-bottom: 4.5rem;
}

#searchResult .venuItemWrap,
#recomVenues .venuItemWrap {
	width: 95%;
}

#searchResult .venuItem .venuItemImg img,
#recomVenues .venuItem .venuItemImg img {
	height: 28rem;
}

#searchResult .vItemWrap,
#recomVenues .vItemWrap {
	position: relative;
	height: 500px;
}

.addFavourite,
#recomVenues .addFavourite {
	cursor: pointer;
	position: absolute;
	top: 1rem;
	right: 3.5rem;
	box-shadow: 0 8px 8px -4px lightblue;
	background-color: #ffffffa3;
	border-radius: 5px;
	padding: 2px;
}

.addFavourite img {
	width: 3.5rem;
}

.addFavourite i {
	font-size: 24px;
	color: var(--yellow);
	margin: 2px;
}

/* Search result ends */

/* Recommended venues start */
#recomVenues {
	padding-top: 2rem;
	padding-bottom: 6rem;
}

.nextBtn {
	margin: 0 auto;
	display: block;
	margin-top: 2rem;
	width: 18.5rem;
	padding: 1rem 0 !important;
}

.nextBtn .arrow {
	font-weight: 700;
}

/* Recommended venues ends */
/* Services ends */

/****** Single shop starts *****/
/* baner starts */
.shopSlider {
	padding-top: 7rem;
}

.shopSlider .slick-next {
	top: 50% !important;
}

.shopSlider .slick-slide img {
	width: 100%;
	height: 50vh;
	object-fit: cover;
}

.shopSlider .slick-arrow.slick-prev {
	left: 0;
}

/* baner starts */
.load img {
	height: 300px;
	width: 300px;
	object-fit: cover;
}

/* about starts */
#shopAbout {
	padding-top: 1.5rem;
	padding-bottom: 2rem;
}

.aboutLeft p {
	color: #504F4F;
	font-size: 1.8rem;
	margin-bottom: .5rem;
}

#shopAbout .container-fluid {
	padding: 0 3% !important;
}

#shopAbout .sectionHeading {
	padding-left: 0;
	margin-bottom: 3.5rem;
}

#shopAbout .rating span,
#shopAbout .rating p {
	font-size: 2rem;
	font-weight: 700;
	color: #FF9900;
}

.sectionHeading .shop-name {
	color: var(--blue1);
}

/* .css-ryrseu-MuiRating-root{
	color: #FF9900!important;
} */
#shopAbout .ratingCount span {
	color: #707070;
	font-weight: 400;
}

#shopAbout .ratingCounter {
	padding-right: .5rem;
}

.location {
	display: flex;
	align-items: center;
}

.location span {
	color: #707070;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.2;
	padding-right: 1rem;
	text-transform: capitalize;
}

.location img {
	width: 70%;
}

.dir {
	color: var(--blue1);
	text-decoration: none;
	font-size: 2.5rem;
	font-weight: 400;
	margin: 1rem 0;
	display: block;
}

.timeWrap .clock {
	font-size: 18px;
	color: var(--yellow);
}

.timeWrap span {
	color: #333131c9;
	font-size: 1.5rem;
	font-weight: 500;
}

.timeWrap {
	display: flex;
	align-items: center;
}

.shopAboutInner {
	position: relative;
}

/* about ends */

/* Offer starts */
#Offer {
	padding-bottom: 5rem;
}

#Offer .sectionHeading {
	padding-left: 0;
}

.OfferSlider img {
	width: 100% !important;
	height: 86vh;
	object-fit: cover;
}

#Offer .captionTop.carousel-caption {
	top: 0;
	right: 0;
	text-align: right;
	padding-right: 1.8rem;
}

#Offer .captionTop.carousel-caption h2 {
	color: var(--yellow);
	font-size: 5rem;
	font-weight: 400;
	line-height: 1;
	margin-right: 11.5rem;
}

#Offer .captionTop.carousel-caption h2 span {
	font-weight: 700;
	font-size: 5rem;
}

#Offer .captionBottom.carousel-caption {
	left: 0;
	width: 100%;
	bottom: 0;
}

#Offer .captionBottom.carousel-caption h1 {
	color: #ffffff;
	font-size: 4.2rem;
	font-weight: 800;
	text-align: left;
	border-left: .2rem solid #fff;
	margin-left: 12rem;
	padding-left: .5rem;
}

#Offer .captionBottom.carousel-caption span {
	font-size: 4.2rem;
}

.captionBottom1.carousel-caption {
	background: rgba(128, 58, 86, 0.5);
}

.captionBottom2.carousel-caption {
	background: rgba(123, 106, 86, 0.5);
}

.captionBottom3.carousel-caption {
	background: rgba(62, 70, 78, 0.5);
}

.allOfferBtn {
	color: var(--blue1);
	text-decoration: none;
	font-size: 2.5rem;
	margin-top: .5rem;
	display: block;
}

/* Offer ends */

/* Shop services starts */
#ShopServicesRoot {
	padding-bottom: 5rem;
}

#ShopServicesRoot .sectionHeading {
	padding-left: 0;
	margin-bottom: 2rem;
}

.subCategories {
	width: 100%;
}

.category {
	display: flex;
	align-items: center;
	padding: 1.2rem;
	cursor: pointer;
	/* background-color: #f3f2f3; */
}

.SubCategory .btn i {
	color: var(--blue1);
}

.category.show-category {
	cursor: pointer;
	padding: 5px;
	padding-bottom: 10px;
}

.category h5 {
	font-size: 2rem;
	margin-bottom: 0;
	cursor: pointer;
	font-weight: 700;
}

.category.show-category h5 {
	margin-bottom: -10px;
}

.SubCategory {
	line-height: 3;
	font-weight: 600;
	padding: 5px 0;
}

.SubCategory .name,
.SubCategory .price {
	display: flex;
	align-items: center;
}

.SubCategory .price span {
	color: #0D0307;
	font-size: 1.8rem;
}

.sp-price {
	color: red;
	text-decoration: line-through
}

.SubCategory .name span {
	color: #0D0307;
	text-align: left;
}

.SubCategory span {
	line-height: 1.5;
}

.SubCategory .name img,
.SubCategory .price img {
	width: 2rem;
}

.SubCategory .name .price {
	display: flex;
	align-items: center;
}

.CartIcon {
	text-align: right;
	padding-right: 1rem;
}

.category img {
	width: 2.2rem;
}

.CartIcon img {
	width: 3.3rem;
	cursor: pointer;
}

.cartInner {
	background: #f3f2f3;
	text-align: center;
	border-radius: 3.5rem;
	padding: 6rem 2.5rem 2.5rem;
	box-shadow: 0 10px 6px -6px #777;
}

.cartInner h3 {
	color: var(--yellow);
	font-size: 3rem;
	font-weight: 700;
	margin-bottom: 1.5rem;
	text-transform: capitalize;
}

.cartInner h2 {
	color: var(--blue1);
	font-size: 3.5rem;
	font-weight: 800;
	margin-bottom: 4rem;
	position: relative;
}

.cartInner h2::after {
	background: #70707021;
	position: absolute;
	left: 0;
	width: 19.5rem;
	height: .1rem;
	content: "";
	right: 0;
	margin: auto;
	top: 4.8rem;
}

.Category .default-text {
	font-size: 1.6rem;
}
.system_faq .faq_content {
	border: 1px solid #cfcfcf;
	padding: 10px;
	border-radius: 4px;
	margin-left: 18px;
}
.system_faq .name span {
	font-weight: 600;
}
.cartInner .Category,
.totalCost {
	align-items: center;
	background: #FFFFFF;
	border-radius: 1rem;
	padding: 1rem 1.5rem;
	margin-bottom: 1.5rem;
	box-shadow: 0 0 .4rem 0 rgba(0, 0, 0, 0.1);
	font-weight: 600;
}

.cartPrice,
.vatamonunt {
	display: flex;
	align-items: center;
	margin: .5rem 0;
}

.cartInner .Category .cartName {
	margin: .5rem 0;
	text-align: left;
	font-size: 14px;
}

.cartInner .Category .closeIcon {
	box-shadow: 0 0 .4rem 0 rgba(0, 0, 0, 0.1);
	cursor: pointer;
	width: 2rem;
	text-align: center;
	display: inline-block;
	line-height: 2rem;
	margin: .5rem 0;
}

.cartName span {
	color: white;
	font-size: 14px;
	font-weight: 600;
}

.cartPrice span,
.vatamonunt span {
	font-size: 14px;
	font-weight: 600;
}

.cartPrice img,
.vatamonunt img {
	width: 14px;
}

.closeIcon {
	box-shadow: 0 0 .4rem 0 rgba(0, 0, 0, 0.1);
	cursor: pointer;
	width: 2rem;
	text-align: center;
	display: inline-block;
	line-height: 2rem;
}

.closeIcon img {
	width: 2rem;
}

.cartVat {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 5px;
}

.totalCost {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cartVat span.vatinfo {
	color: #0d0307;
	line-height: 2;
}

.arrowTop img {
	width: 3rem;
	cursor: pointer;
}

.cartCheckBtn .primaryBtn {
	margin-right: 10.5rem;
	font-size: 1.8rem;
	display: block;
	width: 13rem;
	border-radius: 1rem !important;
	padding: .8rem 0;
	background: var(--blue1);
	border-color: var(--blue1);
	color: white;
}

/* Select time starts */
/* datepicker */

/* -----------custom datepicker-------------- */
.custom-datepicker {
	box-shadow: 0 3px 10px rgb(0 0 0/.2);
	width: 415px;
}

.react-datepicker {
	font-size: 14px !important;
	border: none !important;
	border-right: 5px groove #cfddef !important;
	width: 315px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	width: 4rem !important;
	line-height: 4rem !important;
}

.react-datepicker__day {
	color: green !important;
}

.react-datepicker__day--selected {
	color: white !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	color: #ccc !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	font-size: 16px !important;
}

.react-datepicker__header {
	background-color: #cfddef !important;
	border-bottom: none !important;
	border-radius: 0 !important;
	width: 310px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
	border-color: white !important;
	top: 12px !important;
}

.react-datepicker__navigation-icon--next {
	left: -10px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	background-color: #3dcc4a !important;
	font-weight: 600 !important;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
	background-color: red !important;
	font-weight: 600 !important;
	color: white !important;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
	background-color: red !important;
}

.chuzy-time {
	width: 100px;
}

.calendar-wrap {
	margin: 0 auto;
	padding: 10px;
	text-align: center;
}

.available-time {
	display: block !important;
	height: 293px;
	width: 100px;
	padding: 10px;
	padding-right: 0;
}

.available-time label {
	cursor: pointer;
	font-size: 14px;
	padding: 5px;
	font-weight: 600;
}

.modal-body .form-check-input {
	cursor: pointer;
	height: 14px;
	width: 14px;
	border: 1px solid var(--blue1);
}

.modal-body .form-check-input:checked[type="radio"] {
	height: 14px;
	width: 14px;
}

.person-select .form-check-input {
	border: 1px solid var(--blue1);
}

.person-select .form-check-input:checked,
.modal-body .form-check-input:checked {
	background-color: var(--blue1);
	border-color: var(--blue1);
}

.select-time-btn-wrap {
	display: block;
	text-align: center;
	margin-bottom: 1.5rem;
}

.select-time-btn-wrap a {
	padding-left: 3rem;
	padding-right: 3rem;
}

/* Select time ends */

/* Shop services ends */

/* Reviews starts */
.reviewsLeft .heading ul {
	padding-left: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

#Review {
	border-top: .1rem solid #70707038;
}

#Review .sectionHeading {
	color: var(--yellow);
	font-size: 4rem;
	margin-bottom: 4rem;
	font-weight: 700;
	padding-left: 0;
}

.reviewsLeft .ratingInfo {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.reviewsLeft {
	width: 100%;
	max-width: 37.7rem;
	padding: 4rem 3rem;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
	border-radius: 4rem 4rem 4rem 0;
}

.reviewsLeft .heading {
	width: 70%;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 2.4rem;
}

.LeftItemWrap .Item {
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
}

.starWrap {
	padding-left: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
}

.progressWrap {
	margin: 0 .8rem;
}

.reviewsLeft .heading ul i {
	color: #FF9900;
	font-size: 2.5rem;
	padding: 0 .5rem;
}

.reviewsLeft .left span {
	color: #707070;
	font-size: 2.2rem;
	font-weight: 700;
}

.right span {
	color: #707070;
	font-size: 2rem;
}

.content .title {
	color: #707070;
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 1rem;
	display: block;
}

.LeftItemWrap .Item .form-check-input[type="checkbox"] {
	border-radius: .6rem !important;
	margin-right: .8rem;
	font-size: 1.8rem;
}

ul.starWrap li i {
	color: #FF9900;
	padding: 0 .2rem;
	font-size: 1.8rem;
}

.progress {
	height: 1rem !important;
	background-color: #D2D2D2 !important;
	border-radius: 1rem !important;
	width: 14.5rem;
}

.progress-bar {
	background-color: #FF9900 !important;
	border-radius: 1rem !important;
}

.icon img {
	width: 2.8rem;
	margin-right: .5rem;
}

.info h5 {
	color: #707070;
	font-size: 1.8rem;
	font-weight: 600;
	margin-bottom: 0;
}

.info p {
	margin-bottom: 0;
	font-size: 1.5rem;
	color: #707070;
	font-weight: 400;
}

.reviewsRightSm {
	width: 100%;
	margin: 0 auto;
	height: auto;
}

.reviewsRightLg {
	width: 100%;
	margin: 0 auto;
	height: 450px;
}

.reviewsRightSm .Item,
.reviewsRightLg .Item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}

.reviewsRightSm .pofileWrap,
.reviewsRightLg .pofileWrap {
	display: flex;
	align-items: center;
}

.video-vetting-box {
	background: var(--blue1);
	color: #ffffff;
	font-size: 16px;
}

.profile {
	background: #CFDDEF;
	width: 6rem;
	height: 6rem;
	text-align: center;
	line-height: 5rem;
	border-radius: 50%;
	line-height: 6rem;
	display: inline-block;
	margin-right: .8rem;
}

.profile span {
	color: #4640EC;
	font-size: 2.4rem;
	font-weight: 600;
}

.comment h5 {
	color: var(--blue1);
	margin-bottom: 0;
	margin-bottom: .4rem;
	font-weight: 400;
	font-size: 2rem;
}

.comment h6 {
	color: #707070;
	font-size: 1.7rem;
	font-weight: 400;
	margin-bottom: 0;
}

.comment p {
	color: #000000;
	font-size: 1.5rem;
	font-weight: 400;
	margin-bottom: 0;
	margin-top: .4rem;
}

/* Reviews ends */

/* Near venues starts */
#nearVenus .venuItem .venuBtn {
	display: none;
}

.row.venuItem.nearVenuItem {
	background: #F3F2F3;
	padding: 3.5rem 0;
	border-radius: 3.5rem;
}

.venuItem ul.slick-dots {
	display: none !important;
}

/* Near venues ends */

/* shopBottom starts */
#shopBottom {
	border-top: .2rem solid #70707014;
}


.ShopBottomInner h5 {
	color: var(--yellow);
	margin-bottom: 0;
	font-weight: 400;
	font-size: 2rem;
}

.ShopBottomInner span {
	color: var(--blue1);
	font-size: 1.8rem;
	margin-right: .8rem;
}

.ShopBottomInner .shopBottomBtn {
	font-size: 1.8rem;
}

#SingleShopRoot #footer {
	background: #ffffff;
}

/* shopBottom ends */

/***** Single shop ends ******/


/***** Pay With Card starts ******/
#payWithCard {
	padding-bottom: 4.5rem;
}

.cardHeader {
	padding: 3rem 2.5rem;
	background: #F3F2F3;
	border-radius: 0 0 3rem 0;
	box-shadow: .2rem .1rem .9rem 0 rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2.8rem;
}

.cardHeader .left-content a {
	text-decoration: none;
	color: var(--yellow);
	font-size: 2rem;
	font-weight: 700;
	display: flex;
	align-items: center;
}

.cardHeader .left-content a img {
	width: 2.5rem;
}

.right-content a img {
	width: 3.5rem;
}

.cardWrap {
	padding: 3rem 2.5rem;
	background: #F3F2F3;
	border-radius: 3rem;
}

.cardWrap .title {
	color: var(--yellow);
	font-size: 2rem;
	font-weight: 700;
	margin-bottom: 1.8rem;
}

#payWithCard .container {
	padding: 0 20%;
}

#payWithCard .cardForm .formInput .form-label {
	color: #0D0307;
	font-size: 1.5rem;
	font-weight: 600;
}

#payWithCard .cardForm .formInput .form-control {
	border: none;
	border-radius: 0;
	padding: 1rem;
	box-shadow: 0 .2rem .5rem 0 rgba(0, 0, 0, 0.1);
}

#payWithCard .cardForm .cardItemWrap h6 {
	color: #0D0307;
	font-size: 1.5rem;
	font-weight: 600;
}

.cardItemWrap {
	display: flex;
	align-items: center;
}

.cardItemWrap img {
	width: 8rem;
}

#payWithCard .cardForm .cardItemWrap h6 {
	color: #0D0307;
	font-size: 1.5rem;
	font-weight: 600;
	margin-right: 1.5rem;
	margin-bottom: 0;
}

#formGridNote {
	height: 23rem;
}

.cardItemWrap {
	margin: 1.5rem 0 !important;
}

#formGridNote {
	resize: none;
}

.confirmBtn {
	background: var(--blue1) !important;
	width: 13rem !important;
	margin: .0rem auto;
	display: block !important;
	margin-top: 4.5rem;
	font-size: 1.8rem !important;
	border-color: var(--blue1) !important;
}

/***** Pay With Card starts ******/

/* Guest profile starts */
#fix-header .navbar.active {
	padding-left: 3rem;
	padding-right: 3rem;
	box-shadow: none;
}

.guestProfile img.avatar {
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	margin-right: .5rem;
	object-fit: cover;
}

.avatar-toggle-btn::after {
	display: none !important;
}

.avatar-toggle-btn {
	background: transparent !important;
	border: none !important;
	display: flex !important;
	align-items: center !important;
	box-shadow: none !important;
}

.guestProfile .dropdown-menu.show.dropdown-menu-end {
	padding: 0;
	border: none;
	box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.2);
	right: 1rem;
}

.guestProfile .dropdown .dropdown-item {
	text-decoration: none;
	padding: 1.5rem;
}

.dropdown-item a {
	color: var(--dark) !important;
}

.guestProfile .dropdown .fa,
.guestProfile .dropdown .fas {
	margin-right: 1rem;
}

.guest-name {
	color: var(--yellow) !important;
	font-size: 2rem;
	font-weight: 700;
}

.guestProfile .dropdown .g-name {
	color: #000;
	font-size: 2rem;
	font-weight: 70rem;
}

.guestProfile .dropdown .g-profile {
	color: #0d6efd;
	font-size: 1.8rem;
	font-weight: 600;
}

.guestProfile .dropdown .top:hover {
	background: transparent;
}

.avatar-toggle-btn .fa-chevron-down {
	color: var(--yellow);
	margin-right: 0 !important;
	font-size: 1.3rem;
}

/* Edit profile starts */
#editprofile {
	padding-bottom: 4rem;
}

#editprofile a {
	text-decoration: none;
}

#editprofile .left-content .img-wrap img {
	width: 14rem;
	height: auto;
	border-radius: 50%;
	margin: 0 auto;
	display: block;
}

#editprofile .left-content {
	background: #ffffff;
	border-radius: 1rem;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.08);
}

#editprofile .right-content {
	background: #ffffff;
	margin-bottom: 2.5rem;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.08);
	border-radius: 1rem;
}

#editprofile .left-content .head {
	text-align: center;
	padding: 2rem;
	border-bottom: .1rem solid #8080806e;
}

#editprofile .left-content .img-wrap {
	position: relative;
	display: inline-block;
}

#editprofile .left-content .img-wrap input[type="file"] {
	-webkit-appearance: none !important;
	appearance: none !important;
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	position: absolute;
	top: 9.1rem;
	right: .0rem;
	opacity: 0;
}

#editprofile .left-content .img-wrap .fas.fa-pen {
	position: absolute;
	bottom: 1.4rem;
	left: 10.5rem;
	width: 3.5rem;
	height: 3.5rem;
	background: var(--yellow);
	border-radius: 50%;
	color: #fff;
	line-height: 3rem;
	text-align: center;
	display: inline-block;
	border: .2rem solid #fff;
	font-size: 1.6rem;
}

#editprofile .left-content .head h3 {
	margin-top: 1.5rem;
	font-weight: 700;
	margin-bottom: .8rem;
	font-size: 2.5rem;
}

#editprofile .left-content .head a {
	text-decoration: none;
	font-size: 1.8rem;
	text-transform: capitalize;
}

#editprofile .left-content .body {
	padding: 2rem;
}

#editprofile .left-content .body {
	margin-bottom: 1.8rem;
}

#editprofile .left-content .body h6 {
	margin-bottom: .2rem;
	font-weight: 700;
	font-size: 1.8rem;
}

#editprofile .left-content .body span {
	font-size: 1.8rem;
}

#editprofile .left-content .footer a {
	text-align: center;
	display: block;
	text-decoration: none;
	color: #FF8F7C;
	border-top: .1rem solid #8080806e;
	padding-top: 1.5rem;
	padding-bottom: 3rem;
	font-size: 1.8rem;
}

#editprofile .right-content h5 {
	font-size: 2.2rem;
	font-weight: 700;
	margin-bottom: .2rem;
}

#editprofile .right-content .top {
	padding: 2rem;
	border-bottom: .1rem solid #8080806e;
}

#editprofile .right-content a.add {
	padding: 2rem;
	display: block;
	font-size: 1.8rem;
}

#editprofile .right-content a.add .fa {
	margin-right: 1rem;
}

#editprofile .right-content .social-links {
	padding: 2rem;
}

#editprofile .right-content .social-links.facebook {
	border-bottom: .1rem solid #8080806e;
}

#editprofile .right-content .social-links img {
	width: 3.2rem;
	margin-right: 1.3rem;
}

#custom-switch {
	font-size: 20px;
}

#editprofile .right-content .social-links span {
	font-size: 1.8rem;
}

.my-notification .btn {
	font-size: 16px;
}

.notification i {
	padding-top: 5px;
	font-size: 1.8rem;
	color: var(--yellow);
}

.notification span {
	top: -10px;
	right: 0px;
}

.info-notification .bottom {
	padding: 2rem;
}

.info-notification .bottom span {
	font-weight: 700;
	margin-bottom: .8rem;
	display: block;
}

.info-btn {
	border-radius: 0 !important;
	background: transparent !important;
	box-shadow: none !important;
}

.info-btn a {
	background: #ffffff;
	color: #000;
	font-size: 1.8rem;
	font-weight: 600;
	width: 35.6rem;
	text-align: center;
	padding: 1rem 0;
	box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.05);
	transition: .3s all ease-in-out;
	border-radius: .5rem;
}

.info-btn a:hover {
	background: var(--yellow);
	color: #fff;
}

-footer {
	margin: 0 auto;
	text-align: center;
}

-footer a {
	margin: 0 .5rem;
}

.pt-150,
.pr-pt-150 {
	padding-top: 15rem;
}

/* Edit profile ends */

/* Upcomming appointments starts */
/* ------------review rating----------- */
.apt-right {
	margin-top: 0;
}

.MuiRating-root span {
	font-size: 3rem !important;
}

.css-dqr9h-MuiRating-label {
	top: 3px;
}

#appointments .appn-tab-heading {
	margin-bottom: 3rem;
}

.past-appn-tab-wrap .appn-tab-heading {
	margin-top: 1.5rem;
}

.appn-tab-heading h3 {
	font-weight: 700;
	font-size: 2.5rem;
	margin-right: 1.5rem;
	margin-bottom: 0;
}

.appn-tab-heading span {
	font-size: 2rem;
	background: #fff;
	display: inline-block;
	text-align: center;
	line-height: 2.7rem;
}

#appointments .appn-tab img {
	width: 6rem;
	height: 6rem;
	object-fit: cover;
	border-radius: 1.5rem;
	margin-right: 2.5rem;
}

#appointments .appn-tab .info h4 {
	font-size: 1.8rem;
	color: #000;
	font-weight: 700;
	margin: .5rem 0;
}

#appointments .appn-tab .info p {
	color: #000;
	font-weight: 500;
}

#appointments .tab-content {
	background: #fff;
	/* padding-bottom: 40px; */
	border-radius: 0 0 1rem 1rem;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
	/* position: relative; */
	/* height: 100%; */
}

#appointments .tab-content .heading {
	background: var(--yellow);
	color: #fff;
	padding: 4rem;
	padding-bottom: 12.5rem;
	/* position: relative; */
	/* margin-bottom: 40px; */
	margin-bottom: -4rem;
}

#appointments .tab-content .heading .status {
	background: green;
	text-align: center;
	width: max-content;
	padding: .6rem .8rem;
	border-radius: 2.5rem;
	display: inline-block;
}

#appointments .tab-content .heading .time {
	margin-bottom: 3rem;
}

#appointments .tab-content .heading .time h2 {
	font-size: 3.5rem;
	margin-bottom: 1.5rem;
}

#appointments .tab-content .heading .status i {
	margin-right: .6rem;
	font-size: 1.6rem;
}

#appointments .tab-content .desc .left img {
	width: 5rem;
	height: 5rem;
	object-fit: cover;
	border-radius: 1.5rem;
	margin-right: 1.8rem;
}

#appointments .tab-content .desc .left h4 {
	font-size: 1.8rem;
	font-weight: 700;
}

#appointments .tab-content .desc .left p {
	color: #fff;
	font-weight: 500;
}

#appointments .tab-content .desc .right {
	display: flex;
	text-align: center;
}

#appointments .tab-content .desc .right {
	cursor: pointer;
	text-decoration: none;
	margin-right: 10px;
}

#appointments .tab-content .desc .right i {
	font-size: 2.5rem;
	color: #fff;
	background: rgba(0, 0, 0, 0.3);
	width: 5rem;
	height: 5rem;
	display: inline-block;
	text-align: center;
	line-height: 4.9rem;
	border-radius: 1.5rem;
	margin-bottom: .5rem;
}

#appointments .tab-content .desc .right span {
	color: #fff;
	font-size: 1.5rem;
}

#appointments .tab-content .recipient-wrap {
	background: #E5E5E5;
	/* position: absolute; */
	width: 89%;
	left: 0;
	right: 0;
	margin: 0 auto;
	bottom: 4rem;
	padding: 2.5rem;
	border-radius: 1rem;
	position: relative;
}

#appointments .tab-content .recipient-wrap .services-item {
	padding: 0;
	margin-bottom: 0;
	border-bottom: .1rem solid gray;
}

#appointments .tab-content .recipient-wrap ul.services-item li {
	margin-bottom: 2rem;
}

#appointments .tab-content .recipient-wrap .services-item h6 {
	font-size: 1.8rem;
	margin-bottom: 0;
}

#appointments .tab-content .recipient-wrap .total-amount li h6 {
	margin-bottom: 0;
	font-weight: 700;
	margin-top: 1rem;
	font-size: 1.8rem;
}

#appointments .tab-content .recipient-wrap .total-amount {
	padding: 0;
	margin-bottom: 0;
	margin-top: 2rem;
}

#appointments .nav-pills .nav-link,
.nav-pills .show>.nav-link {
	cursor: pointer;
	border-radius: 0;
	padding: 1rem;
}

#appointments .nav-pills {
	border-bottom: .1rem solid #80808057;
}

#appointments .nav-pills:last-child {
	border-bottom: none;
}

#appointments .nav-pills .nav-link:hover,
.nav-pills .show>.nav-link {
	color: #000;
	background-color: #fff;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
	border-radius: .5rem;
}

/* Upcomming appointments ends */

/* Favorites starts */

#favorites .card {
	padding: 0;
	box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, 0.1);
	border-radius: 1rem !important;
	padding-bottom: 0;
}

#favorites .favrt-icon {
	position: absolute;
	right: 20px;
	top: 10px;
	font-size: 2.5rem;
	color: var(--yellow);
	cursor: pointer;
}

#favorites .card-img-top {
	border-radius: 1rem 1rem 0 0;
	height: 250px;
	object-fit: cover;
}

#favorites .card-body {
	flex: 1 1 auto;
	padding: 1rem 1rem;
	min-height: 15.2rem;
}

#favorites .card-title {
	color: #000;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-transform: capitalize;
}

#favorites .card-text {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

#favorites .card .fav-rating h6 {
	margin: 0 .8rem;
	color: #000;
	font-weight: 700;
}

#favorites .card .fav-rating .fa-star {
	color: #FF9900;
}

/* Favorites ends */

/* Voucher starts */
.voucher-card {
	padding: 2rem;
	border: none !important;
	box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, 0.1);
	border-radius: 1rem !important;
	padding-bottom: 2rem;
	color: #fff;
}

.voucher-card .voucher-name h4 {
	font-weight: 800;
	font-size: 2.5rem;
	margin-bottom: 5px;
}

.voucher-card .voucher-price {
	display: flex;
	align-items: center;
	justify-content: center;
}

.voucher-card .voucher-price span {
	font-weight: 600;
	margin-right: 1rem;
	margin-bottom: 5px;
}

.voucher-card span {
	font-weight: 600;
}

.voucher-card h6 {
	margin-bottom: 0;
	font-weight: 700;
}

.voucher-card .voucher-name .save-price {
	background: rgba(0, 0, 0, 0.2);
	padding: .4rem 1rem;
	border-radius: .5rem;
	height: 32px;
	display: inline-block;
}

.unsave-price {
	height: 27px;
	display: inline-block;
}

.voucher-card .voucher-value {
	padding: 2rem 0;
	border-top: .1rem solid #ffffff4d;
	border-bottom: .1rem solid #ffffff4d;
}

.voucher-card .voucher-value h6 {
	margin-bottom: 1rem;
}

.voucher-card .voucher-value h2 {
	margin-bottom: 0;
	font-weight: 700;
}

.voucher-card .voucher-desc p {
	color: #ffffff;
	font-size: 2rem;
	font-weight: 500;
}

.voucher-card .voucher-desc a {
	margin-left: .8rem;
	color: #ffffff;
	font-weight: 800;
}

.voucher-card::before {
	position: absolute;
	width: 6rem;
	height: 6rem;
	background: #E5E5E5;
	left: -2.5rem;
	top: 44%;
	content: "";
	border-radius: 50%;
}

.voucher-card::after {
	position: absolute;
	width: 6rem;
	height: 6rem;
	background: #E5E5E5;
	right: -2.5rem;
	top: 44%;
	content: "";
	border-radius: 50%;
}

/* Voucher ends */

/* Guest profile ends */

/* My Profile Starts */
.edit-guest-profile-card {
	background: #ffffff;
	margin-bottom: 2.5rem;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.08);
	border-radius: 1rem !important;
	padding: 3rem;
}

.edit-avatar-wrap {
	position: relative;
	display: inline-block;
}

.edit-avatar-wrap img {
	width: 14rem;
	height: 14rem;
	object-fit: cover;
	margin: 0 auto;
	display: block;
}

.edit-avatar-wrap .fas.fa-pen {
	position: absolute;
	bottom: -10px;
	left: 50%;
	width: 3.5rem;
	height: 3.5rem;
	background: var(--yellow);
	border-radius: 50%;
	color: #fff;
	line-height: 3rem;
	text-align: center;
	display: inline-block;
	border: .2rem solid #fff;
	font-size: 1.6rem;
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	margin-left: 2px;
}

.edit-avatar-wrap input[type="file"] {
	-webkit-appearance: none !important;
	appearance: none !important;
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	position: absolute;
	opacity: 0;
	cursor: pointer;
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	margin-left: 2px;
	bottom: -10px;
	left: 50%;
}

.edit-pr-toolbar {
	margin-bottom: 1.8rem;
}

.edit-pr-toolbar h3 {
	font-weight: 700;
	font-size: 2.5rem;
	margin-right: 3px;
}

.btn.edit-profile-btn {
	background: #37a1d2;
	color: #ffffff;
	padding: .6rem 1.8rem;
	font-size: 1.7rem;
	font-weight: 700;
	border-radius: 5px;
	transition: .2s all ease-in-out;
}

.btn.edit-profile-btn:hover {
	background: var(--yellow);
	color: #ffffff;
}

.edit-pr-toolbar span {
	margin-right: 10px;
}

.edit-pr-badge-item {
	padding: 10px;
	margin-right: 20px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}

.edit-pr-badge-item i {
	font-size: 1.7rem;
	margin-right: .7rem;
	color: #37a1d2;
}

.edit-pr-logout button {
	background: #d9214e;
	color: #fff;
	font-weight: 500;
	font-size: 1.6rem;
	padding: 5px 20px;
	border-radius: 5px;
}

.edit-pr-logout button:hover {
	background: var(--yellow);
	color: #fff;
}

.edit-pr-heading {
	padding: 2rem;
	border-bottom: .1rem solid #8080806e;
}

.input-section-heading {
	color: var(--yellow);
	font-weight: 700;
}

.edit-pr-heading h5 {
	font-size: 2.2rem;
	font-weight: 700;
}

.edit-pr-inner-card {
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.Profile-details .body {
	padding: 2rem;
}

.Profile-details .body h6 {
	margin-bottom: 0;
	font-weight: 700;
	font-size: 1.8rem;
}

.Profile-details .body span {
	font-size: 1.8rem;
}

.add-option-btn {
	padding: .5rem 1.5rem !important;
	border-radius: .8rem !important;
	font-weight: 600 !important;
}

.add-option-btn i {
	font-size: 1.6rem;
	margin-right: .7rem;
}

.add-option-btn span {
	font-size: 1.6rem !important;
}

.pd-2rm {
	padding: 2rem;
}

.stripe {
	width: 200px;
}

.revolut {
	width: 60px !important;
}

.edit-pr-social-links img {
	width: 3.2rem;
	margin-right: 1.3rem;
}

.edit-pr-social-links span {
	font-size: 1.8rem;
}

/* My Profile ends */

/* system pages */
.system p {
	color: black;
}

.privacy-policy ul {
	list-style: disc !important;
	margin-left: 15px;
}

.point-show {
	background-color: var(--blue1);
}

/* ----------------------about us---------------- */
.aboutus-section {
	padding: 30px 0;
}

.aboutus-section i {
	color: green;
}

.aboutus-title {
	margin: 0 0 39px;
	padding: 0 0 11px;
	position: relative;
	text-transform: uppercase;
}

.aboutus-title::after {
	background: var(--blue1) none repeat scroll 0 0;
	bottom: 0;
	content: "";
	height: 2px;
	left: 0;
	position: absolute;
	width: 54px;
}

.aboutus-text {
	text-align: justify;
}

/* -------------------payment status------------------- */
.payment-status .box {
	align-self: flex-end;
	animation-duration: 2s;
	margin: 0 auto 0 auto;
	transform-origin: bottom;
	width: 100px;
}

.payment-status .bounce-5 {
	animation-name: bounce-5;
	animation-timing-function: ease;
}

.payment-status h3 {
	text-transform: capitalize;
}

.payment-status .stage i {
	font-size: 80px;
	color: red;
}

@keyframes bounce-5 {
	0% {
		transform: scale(1, 1) translateY(0);
	}

	10% {
		transform: scale(1.1, .9) translateY(0);
	}

	30% {
		transform: scale(.9, 1.1) translateY(-100px);
	}

	50% {
		transform: scale(1, 1) translateY(0);
	}

	57% {
		transform: scale(1, 1) translateY(-7px);
	}

	64% {
		transform: scale(1, 1) translateY(0);
	}

	100% {
		transform: scale(1, 1) translateY(0);
	}
}

.available-time .spinner-border {
	width: 2rem !important;
	height: 2rem !important;
	border: .25em solid rgb(55, 78, 140) !important;
	border-right-color: #cfddef !important;
}

.service-list {
	height: 28rem;
	width: 100%;
	padding-top: 20%;
	top: 0;
	background-color: var(--yellow)85;
	border-radius: 2rem;
}

.service-list span {
	display: inline-block;
}

.single-voucher ul li {
	line-height: 1.8;
	font-size: 16px;
}

.chuzy-bg {
	background-color: var(--yellow);
}

.quantity {
	top: -10px;
	right: -10px;
	background-color: var(--yellow);
	border-radius: 50%;
	height: 30px;
	width: 30px;
	color: white;
	padding-top: 2px;
}

.qty-inc-dec input {
	height: 20px;
	width: 50px;
	font-size: 16px;
	text-align: center;
}

#Review .css-ryrseu-MuiRating-root {
	color: #F90;
}

.inv-error {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.cartItems {
	height: 200px;
	width: 100%;
}

.fa-eye-slash,
.fa-eye {
	cursor: pointer;
}

.p-way img {
	cursor: pointer;
	width: 180px;
}

.voucher-valid-time {
	height: 60px;
}

/* -------------------Demo apps--------------------- */
.mobile-app {
	background-color: var(--yellow);
}

.guest_app {
	background-color: var(--blue1) !important;
	margin-bottom: -30px;
	padding-bottom: 45px;
	padding-top: 20px;
}

.mobile-app .slick-prev {
	left: 0 !important;
}

.mobile-app-left img {
	height: 600px;
	width: 300px;
	object-fit: contain;
}

/* -------------------------------Blog post------------------- */
.blog-card .outer-card {
	height: 600px;
}

.blog-card .card-img-top {
	height: 300px;
	object-fit: cover;
	margin-bottom: 10px;
}

.blog-card p a {
	color: var(--yellow);
}

.blog-card h2 {
	color: var(--yellow);
	text-align: center;
	font-weight: bolder;
	font-size: 24px;
}

.FeatureSlider iframe {
	width: 100%;
	height: 100vh;
}

.form-error {
	color: red;
	font-size: 14px;
}

/* ----------------single partner feature-------------- */
.single-partner-feature {
	padding: 80px 0;
	min-height: 90vh;
}

.single-blog p {
	text-align: justify;
}

.list-group-item {
	padding: 1rem !important;
	border: none !important;
	margin-bottom: 5px;
	background: linear-gradient(to left, var(--blue1) 50%, var(--yellow) 50%) right;
	background-size: 200%;
	cursor: pointer;
	transition: .5s ease-out;
}

.list-group-item:hover {
	background-position: left;
}

.list-group-item:hover a {
	color: white;
}

.list-group-item a {
	color: white;
}

.comma:not(:last-child):after {
	content: ", ";
}

.single-partner-feature .feature-icon {
	width: 48px;
	height: 48px;
	object-fit: cover;
}

/* --------------------------call-to-action-------------------- */
.call-to-action .action-top,
.call-to-action .action-bottom {
	width: 330px;
	padding: 35px;
	font-size: 16px;
}

.call-to-action-guest .action-top,
.call-to-action-guest .action-bottom {
	background-color: var(--yellow);
	color: var(--blue2);
	border-radius: 6rem 6rem 6rem 0 !important;
	box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
}

.call-to-action-guest .action-bottom {
	border-radius: 6rem 6rem 0 6rem !important;
}

.call-to-action-guest a {
	color: var(--blue2);
	font-size: 16px;
	transition: 0.5s ease;
}

.call-to-action-guest a:hover {
	color: white;
}

.call-to-action-business .action-top,
.call-to-action-business .action-bottom {
	background-color: var(--blue1);
	color: var(--light);
	border-radius: 6rem 6rem 6rem 0 !important;
	box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
	width: 330px;
}

.call-to-action-business .action-bottom {
	border-radius: 6rem 6rem 0 6rem !important;
}

.call-to-action-business a {
	color: var(--light);
	font-size: 20px;
	transition: 0.5s ease;
}

.call-to-action-business a:hover {
	color: var(--yellow);
}

.action-top .action-text {
	font-weight: 700;
	font-size: 32px;
}

.action-bottom .apk img {
	width: 130px;
}

.action_logo {
	width: 160px;
}

.call-to-action-guest .action-top .btn {
	color: #037CAE;
	font-size: 18px;
	border: 0;
	outline: 0;
	padding: 10px 20px;
	box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
}

.call-to-action-business .action-top .btn {
	color: var(--yellow);
	font-size: 18px;
	border: 0;
	outline: 0;
	padding: 10px 20px;
	box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
}

.bottom-text {
	font-size: 14px;
}

.sticky-action {
	position: sticky;
	right: 0;
	top: 150px;
}

#footer a.business {
	color: var(--light);
	background-color: var(--blue1);
	font-size: 16px;
	border-radius: 8rem 8rem 8rem 0 !important;
	border: none;
	box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
}

#footer a.guest {
	color: var(--light);
	background-color: var(--yellow);
	font-size: 16px;
	border-radius: 8rem 8rem 8rem 0 !important;
	border: none;
	box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
}

/* --------------------delete-account----------------- */
.delete-account.venuItem .venuBtn {
	font-size: 16px;
}

.for_guest_bg {
	background: var(--yellow);
}

.for_guest_bar {
	padding-top: 28px;
	position: relative;
}

.for_guest_bar::after {
	position: absolute;
	bottom: -50px;
	content: '';
	width: 100%;
	height: 25px;
	background-color: var(--light);
	display: block;
	left: 0;
}
.servicesItemWrap {
    margin-top: 30px;
}
.faq_ans{
	margin-left: 10px;
}
.faq-static-section{
	text-align: center;
}
.faq-search-inner {
	position: relative;
	margin-right: 1rem;
}

.faq-search-inner .fa-search {
	position: absolute;
    top: 1rem;
    left: 14rem;
    cursor: pointer;
    font-size: 2.2rem;
    color: gray;
}

.faq-search-inner input {
	width: 50rem;
	padding: 1rem;
	padding-left: 4.8rem;
	border: .1rem solid #80808042;
	border-radius: 25px;
	font-size: 14px;
	box-shadow: 0 0 .4rem 0 rgba(0, 0, 0, 0.1);
}